// @flow

import type {
  Site,
  PartnerSupport,
  SalesPerson,
  Bounds,
  EInvoiceOperator
} from './types';
import type { JSONObject, JSONValue } from 'common/types';
import {
  object,
  record,
  string,
  number,
  locale,
  nullable,
  array,
  items
} from 'common/json/extract';

type SitesJSON = { [key: string]: JSONObject, ... };
const sitesJSON: SitesJSON = require('railsRoot/config/sites.json');

export default function allSites(): Array<Site> {
  return Object.keys(sitesJSON).map(key => {
    return siteFromJSON(key, sitesJSON[key]);
  });
}

const extractPartnerSupport: JSONValue => PartnerSupport = object(
  record({
    mail: string,
    phone: nullable(string)
  })
);

const extractSalesPerson: JSONValue => SalesPerson = object(
  record({
    team_mail: string,
    first_name: string,
    image: string,
    mail: string,
    name: string,
    phone: nullable(string)
  })
);

const requiredBounds: JSONValue => Bounds = object(
  record({
    south: number,
    west: number,
    north: number,
    east: number
  })
);

const eInvoiceOperator: JSONValue => EInvoiceOperator = object(
  record({
    name: string,
    id: string
  })
);

const extractEInvoiceoperators: JSONValue => Array<EInvoiceOperator> = array(
  items(eInvoiceOperator)
);

function siteFromJSON(key: string, json: JSONObject): Site {
  return {
    key: key,
    locale: locale(json.locale),
    baseDomain: requiredString(json.base_domain),
    countryInPath: optionalString(json.country_in_path),
    defaultOgImage: optionalString(json.default_og_image),
    defaultSearchBounds: requiredBounds(json.default_search_bounds),
    defaultMapCenter: requiredLocation(json.default_map_center),
    iso31661Alpha2: optionalString(json.iso_3166_1_alpha_2),
    iso31661Alpha3: optionalString(json.iso_3166_1_alpha_3),
    profileTimeZone: requiredString(json.profile_time_zone),
    partnerSupport: extractPartnerSupport(json.partner_support),
    salesPerson: extractSalesPerson(json.sales_person),
    eInvoiceOperators: extractEInvoiceoperators(json.e_invoice_operators)
  };
}

function requiredString(maybeString: JSONValue): string {
  if (typeof maybeString !== 'string') {
    throw new Error(
      `Expected string, got ${typeof maybeString}: ${String(maybeString)}`
    );
  }
  return maybeString;
}

function optionalString(maybeString: JSONValue): string | null {
  if (typeof maybeString !== 'string') {
    return null;
  }
  return maybeString;
}

function requiredLocation(maybeLocation: JSONValue): [number, number] {
  // $FlowFixMe[incompatible-return]
  return maybeLocation;
}
