// @flow
// NOTE: This file is also imported from frontend JS application so that this
//       file is the only one acting as the source of truth of what are the
//       available locales!

// Use a tuple type for locales as that disallows both adding new locales
// or removing old locales without changing types.

// Note: this file is not auto-formatted with Prettier as it would remove the
// flow comments

const locales /*: ['fi', 'sv', 'en', 'da', 'pl', 'es', 'tra'] */ = [
  'fi',
  'sv',
  'en',
  'da',
  'pl',
  'es',
  'tra'
];

/*::
export type Locale = 'fi' | 'sv' | 'en' | 'da' | 'pl' | 'es' | 'tra';

// This makes sure that all defined locales are specified in Locale type above
(locales.map(locale => (locale: Locale)));
*/

module.exports = { locales };
